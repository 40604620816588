import React, { cloneElement, type ReactElement, useContext } from 'react';
import type { RichTranslationValues } from 'next-intl';
import { I18nContext, Trans as ReactI18NextTrans } from 'react-i18next';
import type { useTranslations } from './useTranslations';

interface TransProps {
  t: ReturnType<typeof useTranslations>;
  i18nKey: string;
  components?: Record<string, ReactElement>;
  values?: Record<string, string | number | undefined | null>;
}

export type TFunctionResult = ReturnType<typeof Trans>;

// react doesn't support children inside self-closing tags so we omit passing children for specified components
const selfClosingTags = ['br', 'hr', 'img', 'input'];

export const NextIntlTrans = ({
  t,
  i18nKey,
  components = {},
  values = {},
}: TransProps) => {
  // @ts-expect-error wrong typings for migration but still works
  return t.rich(i18nKey, {
    ...values,
    ...Object.keys(components).reduce((acc, key) => {
      const comp = components[key]! || values[key]!;
      acc[key] = (chunks) =>
        cloneElement(
          comp,
          comp.props,
          selfClosingTags.includes(key) ? undefined : chunks,
        );

      return acc;
    }, {} as RichTranslationValues),
  });
};

export function Trans(props: TransProps) {
  try {
    const i18nContext = useContext(I18nContext); // Check if react-i18next context is available

    if (i18nContext) {
      // @ts-expect-error wrong typings for migration but still works
      return <ReactI18NextTrans {...props} />;
    }
  } catch (_) {
    // react-i18next context is not found, falling back to next-intl
  }

  return <NextIntlTrans {...props} />;
}
