import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { contentfulImageLoader } from 'src/cms/utils/utils';

/*
  Local images crash stage env for unknown reason, platform team has been informed and are looking into it.
  Force loader prop to ensure this is only used for external images for now.
 */
export interface NextImageProps extends ImageProps {
  src: string;
}

export const NextImage = ({
  src,
  style,
  alt = '',
  sizes,
  quality = 75,
  ...rest
}: NextImageProps) => (
  <Image
    src={src}
    style={{ maxWidth: '100%', height: 'auto', ...style }}
    alt={alt}
    quality={quality}
    sizes={sizes}
    loader={contentfulImageLoader}
    {...rest}
  />
);

export default NextImage;
