import type { DecoratedImageT } from 'src/ui/DecoratedImage';
import type { MotionT } from 'src/ui/Motion';
import type { TemplateViewT } from 'src/ui/Template';
import type {
  Asset,
  IImageFields,
  IMotionFields,
  IPersonFields,
  ITemplateFields,
  ITemplatePageFields,
} from 'types/generated/contentful';

export const getTemplateProps = ({
  title,
  presentationId,
  questionId,
  preview,
}: ITemplateFields): TemplateViewT => ({
  title,
  presentationId,
  questionId,
  preview: preview?.fields?.file.url,
});

export const getTemplatePageProps = ({
  template,
  title,
  presentationId,
  questionId,
  slug,
}: ITemplatePageFields): TemplateViewT => ({
  title: template.fields.title ?? title,
  presentationId: template.fields?.presentationId ?? presentationId,
  questionId: template.fields?.questionId ?? questionId,
  preview: template.fields.preview?.fields?.file.url,
  slug,
  pro: template.fields.usesProFeatures,
});

export const getPersonFullName = ({ name, lastName }: Partial<IPersonFields>) =>
  `${name}${lastName ? ` ${lastName}` : ''}`;

export const getImageProps = (image: IImageFields): DecoratedImageT => ({
  src: image.image.fields.file.url,
  alt: image.alt,
  decoration: image.decoration,
  caption: image.caption,
});

export const getMotionProps = (motion: IMotionFields): MotionT => ({
  src: motion.motionUrl || motion.motion.fields.file.url,
  posterSrc: motion.poster?.fields?.file.url,
});

export const contentfulImageLoader = ({
  src,
  format = 'webp',
  width,
  quality = 80,
}: {
  src: string;
  width: number;
  quality?: number;
  format?: string;
}) => {
  const isSvg = src.match('.svg');
  return `https:${src}?${isSvg ? '' : `fm=${format}`}&w=${width}&q=${quality}`;
};

export const getContentfulImageProps = (image: IImageFields) => ({
  alt: image.alt,
  decoration: image.decoration,
  ...getContentfulAssetImageProps(image.image),
});

export const getContentfulAssetImageProps = (image: Asset) => ({
  src: image.fields.file.url,
  ...image.fields.file.details.image,
});

type ObjectType = Record<string, unknown>;

function isObject(item: any): item is ObjectType {
  return !!item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep<T, S>(target: T, ...sources: S[]): T {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key] as ObjectType, source[key] as ObjectType);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
