export const isoCountries = [
  { key: 'AF', value: 'Afghanistan', vat: false },
  { key: 'AX', value: 'Aland Islands', vat: false },
  { key: 'AL', value: 'Albania', vat: false },
  { key: 'DZ', value: 'Algeria', vat: false },
  { key: 'AS', value: 'American Samoa', vat: false },
  { key: 'AD', value: 'Andorra', vat: false },
  { key: 'AO', value: 'Angola', vat: false },
  { key: 'AI', value: 'Anguilla', vat: false },
  { key: 'AQ', value: 'Antarctica', vat: false },
  { key: 'AG', value: 'Antigua and Barbuda', vat: false },
  { key: 'AR', value: 'Argentina', vat: false },
  { key: 'AM', value: 'Armenia', vat: false },
  { key: 'AW', value: 'Aruba', vat: false },
  { key: 'AU', value: 'Australia', vat: false },
  { key: 'AT', value: 'Austria', vat: true },
  { key: 'AZ', value: 'Azerbaijan', vat: false },
  { key: 'BS', value: 'Bahamas', vat: false },
  { key: 'BH', value: 'Bahrain', vat: false },
  { key: 'BD', value: 'Bangladesh', vat: false },
  { key: 'BB', value: 'Barbados', vat: false },
  { key: 'BY', value: 'Belarus', vat: false },
  { key: 'BE', value: 'Belgium', vat: true },
  { key: 'BZ', value: 'Belize', vat: false },
  { key: 'BJ', value: 'Benin', vat: false },
  { key: 'BM', value: 'Bermuda', vat: false },
  { key: 'BT', value: 'Bhutan', vat: false },
  { key: 'BO', value: 'Bolivia (Plurinational State of)', vat: false },
  { key: 'BQ', value: 'Bonaire, Sint Eustatius and Saba', vat: false },
  { key: 'BA', value: 'Bosnia and Herzegovina', vat: false },
  { key: 'BW', value: 'Botswana', vat: false },
  { key: 'BV', value: 'Bouvet Island', vat: false },
  { key: 'BR', value: 'Brazil', vat: false },
  { key: 'IO', value: 'British Indian Ocean Territory', vat: false },
  { key: 'BN', value: 'Brunei Darussalam', vat: false },
  { key: 'BG', value: 'Bulgaria', vat: true },
  { key: 'BF', value: 'Burkina Faso', vat: false },
  { key: 'BI', value: 'Burundi', vat: false },
  { key: 'KH', value: 'Cambodia', vat: false },
  { key: 'CM', value: 'Cameroon', vat: false },
  { key: 'CA', value: 'Canada', vat: false },
  { key: 'CV', value: 'Cabo Verde', vat: false },
  { key: 'KY', value: 'Cayman Islands', vat: false },
  { key: 'CF', value: 'Central African Republic', vat: false },
  { key: 'TD', value: 'Chad', vat: false },
  { key: 'CL', value: 'Chile', vat: false },
  { key: 'CN', value: 'China', vat: false },
  { key: 'CX', value: 'Christmas Island', vat: false },
  { key: 'CC', value: 'Cocos (Keeling) Islands', vat: false },
  { key: 'CO', value: 'Colombia', vat: false },
  { key: 'KM', value: 'Comoros', vat: false },
  { key: 'CG', value: 'Congo (Republic of the)', vat: false },
  { key: 'CD', value: 'Congo (Democratic Republic of the)', vat: false },
  { key: 'CK', value: 'Cook Islands', vat: false },
  { key: 'CR', value: 'Costa Rica', vat: false },
  { key: 'CI', value: "Côte d'Ivoire", vat: false },
  { key: 'HR', value: 'Croatia', vat: true },
  { key: 'CU', value: 'Cuba', vat: false },
  { key: 'CW', value: 'Curaçao', vat: false },
  { key: 'CY', value: 'Cyprus', vat: true },
  { key: 'CZ', value: 'Czech Republic', vat: true },
  { key: 'DK', value: 'Denmark', vat: true },
  { key: 'DJ', value: 'Djibouti', vat: false },
  { key: 'DM', value: 'Dominica', vat: false },
  { key: 'DO', value: 'Dominican Republic', vat: false },
  { key: 'EC', value: 'Ecuador', vat: false },
  { key: 'EG', value: 'Egypt', vat: false },
  { key: 'SV', value: 'El Salvador', vat: false },
  { key: 'GQ', value: 'Equatorial Guinea', vat: false },
  { key: 'ER', value: 'Eritrea', vat: false },
  { key: 'EE', value: 'Estonia', vat: true },
  { key: 'ET', value: 'Ethiopia', vat: false },
  { key: 'FK', value: 'Falkland Islands (Malvinas)', vat: false },
  { key: 'FO', value: 'Faroe Islands', vat: false },
  { key: 'FJ', value: 'Fiji', vat: false },
  { key: 'FI', value: 'Finland', vat: false },
  { key: 'FR', value: 'France', vat: true },
  { key: 'GF', value: 'French Guiana', vat: false },
  { key: 'PF', value: 'French Polynesia', vat: false },
  { key: 'TF', value: 'French Southern Territories', vat: false },
  { key: 'GA', value: 'Gabon', vat: false },
  { key: 'GM', value: 'Gambia', vat: false },
  { key: 'GE', value: 'Georgia', vat: false },
  { key: 'DE', value: 'Germany', vat: true },
  { key: 'GH', value: 'Ghana', vat: false },
  { key: 'GI', value: 'Gibraltar', vat: false },
  { key: 'GR', value: 'Greece', vat: true },
  { key: 'GL', value: 'Greenland', vat: false },
  { key: 'GD', value: 'Grenada', vat: false },
  { key: 'GP', value: 'Guadeloupe', vat: false },
  { key: 'GU', value: 'Guam', vat: false },
  { key: 'GT', value: 'Guatemala', vat: false },
  { key: 'GG', value: 'Guernsey', vat: false },
  { key: 'GN', value: 'Guinea', vat: false },
  { key: 'GW', value: 'Guinea-Bissau', vat: false },
  { key: 'GY', value: 'Guyana', vat: false },
  { key: 'HT', value: 'Haiti', vat: false },
  { key: 'HM', value: 'Heard Island and McDonald Islands', vat: false },
  { key: 'VA', value: 'Holy See', vat: false },
  { key: 'HN', value: 'Honduras', vat: false },
  { key: 'HK', value: 'Hong Kong', vat: false },
  { key: 'HU', value: 'Hungary', vat: true },
  { key: 'IS', value: 'Iceland', vat: false },
  { key: 'IN', value: 'India', vat: false },
  { key: 'ID', value: 'Indonesia', vat: false },
  { key: 'IR', value: 'Iran (Islamic Republic of)', vat: false },
  { key: 'IQ', value: 'Iraq', vat: false },
  { key: 'IE', value: 'Ireland', vat: true },
  { key: 'IM', value: 'Isle of Man', vat: false },
  { key: 'IL', value: 'Israel', vat: false },
  { key: 'IT', value: 'Italy', vat: true },
  { key: 'JM', value: 'Jamaica', vat: false },
  { key: 'JP', value: 'Japan', vat: false },
  { key: 'JE', value: 'Jersey', vat: false },
  { key: 'JO', value: 'Jordan', vat: false },
  { key: 'KZ', value: 'Kazakhstan', vat: false },
  { key: 'KE', value: 'Kenya', vat: false },
  { key: 'KI', value: 'Kiribati', vat: false },
  { key: 'KP', value: "Korea (Democratic People's Republic of)", vat: false },
  { key: 'KR', value: 'Korea (Republic of)', vat: false },
  { key: 'KW', value: 'Kuwait', vat: false },
  { key: 'KG', value: 'Kyrgyzstan', vat: false },
  { key: 'LA', value: "Lao People's Democratic Republic", vat: false },
  { key: 'LV', value: 'Latvia', vat: true },
  { key: 'LB', value: 'Lebanon', vat: false },
  { key: 'LS', value: 'Lesotho', vat: false },
  { key: 'LR', value: 'Liberia', vat: false },
  { key: 'LY', value: 'Libya', vat: false },
  { key: 'LI', value: 'Liechtenstein', vat: false },
  { key: 'LT', value: 'Lithuania', vat: true },
  { key: 'LU', value: 'Luxembourg', vat: true },
  { key: 'MO', value: 'Macao', vat: false },
  {
    key: 'MK',
    value: 'Macedonia (the former Yugoslav Republic of)',
    vat: false,
  },
  { key: 'MG', value: 'Madagascar', vat: false },
  { key: 'MW', value: 'Malawi', vat: false },
  { key: 'MY', value: 'Malaysia', vat: false },
  { key: 'MV', value: 'Maldives', vat: false },
  { key: 'ML', value: 'Mali', vat: false },
  { key: 'MT', value: 'Malta', vat: true },
  { key: 'MH', value: 'Marshall Islands', vat: false },
  { key: 'MQ', value: 'Martinique', vat: false },
  { key: 'MR', value: 'Mauritania', vat: false },
  { key: 'MU', value: 'Mauritius', vat: false },
  { key: 'YT', value: 'Mayotte', vat: false },
  { key: 'MX', value: 'Mexico', vat: false },
  { key: 'FM', value: 'Micronesia (Federated States of)', vat: false },
  { key: 'MD', value: 'Moldova (Republic of)', vat: false },
  { key: 'MC', value: 'Monaco', vat: true },
  { key: 'MN', value: 'Mongolia', vat: false },
  { key: 'ME', value: 'Montenegro', vat: false },
  { key: 'MS', value: 'Montserrat', vat: false },
  { key: 'MA', value: 'Morocco', vat: false },
  { key: 'MZ', value: 'Mozambique', vat: false },
  { key: 'MM', value: 'Myanmar', vat: false },
  { key: 'NA', value: 'Namibia', vat: false },
  { key: 'NR', value: 'Nauru', vat: false },
  { key: 'NP', value: 'Nepal', vat: false },
  { key: 'NL', value: 'Netherlands', vat: true },
  { key: 'NC', value: 'New Caledonia', vat: false },
  { key: 'NZ', value: 'New Zealand', vat: false },
  { key: 'NI', value: 'Nicaragua', vat: false },
  { key: 'NE', value: 'Niger', vat: false },
  { key: 'NG', value: 'Nigeria', vat: false },
  { key: 'NU', value: 'Niue', vat: false },
  { key: 'NF', value: 'Norfolk Island', vat: false },
  { key: 'MP', value: 'Northern Mariana Islands', vat: false },
  { key: 'NO', value: 'Norway', vat: false },
  { key: 'OM', value: 'Oman', vat: false },
  { key: 'PK', value: 'Pakistan', vat: false },
  { key: 'PW', value: 'Palau', vat: false },
  { key: 'PS', value: 'Palestine, State of', vat: false },
  { key: 'PA', value: 'Panama', vat: false },
  { key: 'PG', value: 'Papua New Guinea', vat: false },
  { key: 'PY', value: 'Paraguay', vat: false },
  { key: 'PE', value: 'Peru', vat: false },
  { key: 'PH', value: 'Philippines', vat: false },
  { key: 'PN', value: 'Pitcairn', vat: false },
  { key: 'PL', value: 'Poland', vat: true },
  { key: 'PT', value: 'Portugal', vat: true },
  { key: 'PR', value: 'Puerto Rico', vat: false },
  { key: 'QA', value: 'Qatar', vat: false },
  { key: 'RE', value: 'Réunion', vat: false },
  { key: 'RO', value: 'Romania', vat: true },
  { key: 'RU', value: 'Russian Federation', vat: false },
  { key: 'RW', value: 'Rwanda', vat: false },
  { key: 'BL', value: 'Saint Barthélemy', vat: false },
  {
    key: 'SH',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    vat: false,
  },
  { key: 'KN', value: 'Saint Kitts and Nevis', vat: false },
  { key: 'LC', value: 'Saint Lucia', vat: false },
  { key: 'MF', value: 'Saint Martin (French part)', vat: false },
  { key: 'PM', value: 'Saint Pierre and Miquelon', vat: false },
  { key: 'VC', value: 'Saint Vincent and the Grenadines', vat: false },
  { key: 'WS', value: 'Samoa', vat: false },
  { key: 'SM', value: 'San Marino', vat: false },
  { key: 'ST', value: 'Sao Tome and Principe', vat: false },
  { key: 'SA', value: 'Saudi Arabia', vat: false },
  { key: 'SN', value: 'Senegal', vat: false },
  { key: 'RS', value: 'Serbia', vat: false },
  { key: 'SC', value: 'Seychelles', vat: false },
  { key: 'SL', value: 'Sierra Leone', vat: false },
  { key: 'SG', value: 'Singapore', vat: false },
  { key: 'SX', value: 'Sint Maarten (Dutch part)', vat: false },
  { key: 'SK', value: 'Slovakia', vat: true },
  { key: 'SI', value: 'Slovenia', vat: true },
  { key: 'SB', value: 'Solomon Islands', vat: false },
  { key: 'SO', value: 'Somalia', vat: false },
  { key: 'ZA', value: 'South Africa', vat: false },
  {
    key: 'GS',
    value: 'South Georgia and the South Sandwich Islands',
    vat: false,
  },
  { key: 'SS', value: 'South Sudan', vat: false },
  { key: 'ES', value: 'Spain', vat: true },
  { key: 'LK', value: 'Sri Lanka', vat: false },
  { key: 'SD', value: 'Sudan', vat: false },
  { key: 'SR', value: 'Suriname', vat: false },
  { key: 'SJ', value: 'Svalbard and Jan Mayen', vat: false },
  { key: 'SZ', value: 'Swaziland', vat: false },
  { key: 'SE', value: 'Sweden', vat: true },
  { key: 'CH', value: 'Switzerland', vat: false },
  { key: 'SY', value: 'Syrian Arab Republic', vat: false },
  { key: 'TW', value: 'Taiwan, Province of China', vat: false },
  { key: 'TJ', value: 'Tajikistan', vat: false },
  { key: 'TZ', value: 'Tanzania, United Republic of', vat: false },
  { key: 'TH', value: 'Thailand', vat: false },
  { key: 'TL', value: 'Timor-Leste', vat: false },
  { key: 'TG', value: 'Togo', vat: false },
  { key: 'TK', value: 'Tokelau', vat: false },
  { key: 'TO', value: 'Tonga', vat: false },
  { key: 'TT', value: 'Trinidad and Tobago', vat: false },
  { key: 'TN', value: 'Tunisia', vat: false },
  { key: 'TR', value: 'Turkey', vat: false },
  { key: 'TM', value: 'Turkmenistan', vat: false },
  { key: 'TC', value: 'Turks and Caicos Islands', vat: false },
  { key: 'TV', value: 'Tuvalu', vat: false },
  { key: 'UG', value: 'Uganda', vat: false },
  { key: 'UA', value: 'Ukraine', vat: false },
  { key: 'AE', value: 'United Arab Emirates', vat: false },
  { key: 'GB', value: 'United Kingdom', vat: false },
  { key: 'US', value: 'United States', vat: false },
  { key: 'UM', value: 'United States Minor Outlying Islands', vat: false },
  { key: 'UY', value: 'Uruguay', vat: false },
  { key: 'UZ', value: 'Uzbekistan', vat: false },
  { key: 'VU', value: 'Vanuatu', vat: false },
  { key: 'VE', value: 'Venezuela (Bolivarian Republic of)', vat: false },
  { key: 'VN', value: 'Viet Nam', vat: false },
  { key: 'VG', value: 'Virgin Islands (British)', vat: false },
  { key: 'VI', value: 'Virgin Islands (U.S.)', vat: false },
  { key: 'WF', value: 'Wallis and Futuna', vat: false },
  { key: 'EH', value: 'Western Sahara', vat: false },
  { key: 'YE', value: 'Yemen', vat: false },
  { key: 'ZM', value: 'Zambia', vat: false },
  { key: 'ZW', value: 'Zimbabwe', vat: false },
];
